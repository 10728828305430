<template>
	<Dropdown
		class="dropdown-custom w-full"
		v-model="model"
		:filter="false"
		:showClear="false"
		:pt="{
			panel: { style:{maxWidth: '462px'} },
		}">
	</Dropdown>
</template>

<script setup>
import Dropdown from "primevue/dropdown"
import { computed } from "vue"
import { useStoreSite } from "@/stores/store.site"

const storeSite = useStoreSite()

const props = defineProps({
	//modelValue: { type: [String, Number], default: "" },
	modelValue: { type: [String, Number, Object], default: "" },
	pLabel: { type: [String, Number], default: "" },
})

const emit = defineEmits(["update:modelValue"])

const model = computed({
	get() {
		return props.modelValue
	},

	set(value) {
		return emit("update:modelValue", value)
	},
})
</script>

<style lang="scss" scoped>


.dropdown-custom {
	border: none;
    border-radius: 0;
    border-bottom: 1px solid #ECECEC;
    background-color: #F5FBFF;
    align-items: center;
   
    &:hover {
      border-bottom: 1px solid #ECECEC;
    }

    &.error.p-invalid {
        border-bottom: 2px solid #FF6600;
    }
  }
  

</style>
