<script setup>
import { ref, computed } from "vue"
import VueDatePicker from "@vuepic/vue-datepicker"
import "@vuepic/vue-datepicker/dist/main.css"

const props = defineProps({
	isError: {type:Boolean, default:false},
	pLabel: { type: String, default: "" },
	modelValue: { type: String },
})
const isFocus = ref(false)
const tmpDate = ref(null)
const emit = defineEmits(["update:modelValue"])

const value = computed({
	get() {
		return props.modelValue
	},
	set(value) {
		let modifiedValue = JSON.stringify(value).replace(/"/g, "")

		if(modifiedValue == "null") modifiedValue = ""
		emit("update:modelValue", modifiedValue)
	},
})

function handleUpdate(date) {
	tmpDate.value = date;
	date ? isFocus.value = true : isFocus.value = false;
}
function handleInitFocus() {
	isFocus.value = true;
}
function handleBlur() {
	tmpDate.value ? isFocus.value = true : isFocus.value = false;
}
</script>

<template>
	<div class="datepicker-wrapper">
		<span class="p-float-label">
			<VueDatePicker
			@blur = "handleBlur"
			@focus ="handleInitFocus"
			:auto-apply="true"
			@update:model-value="handleUpdate"
				v-model="value"
				:format="'dd/MM/yyyy'"
				class="datepicker-custom"
				:class="{'error': isError}"
				:enable-time-picker="false"
				placeholder=""
				:text-input="{ enterSubmit: true }" />
			<label :class="{'custom-float-label' : isFocus || value}">{{ props.pLabel }}</label>
		</span>
	</div>
</template>

<style lang="scss">
/* override datepicker component */
.dp__input.dp__input_icon_pad {
	background-color: #F5FBFF;
	border: none;
	color: #495057;
	padding: 0;
}
.dp__icon {
	display: none;
}
</style>

<style scoped lang="scss">
.datepicker-custom {
	background-color: #F5FBFF;
	border: none;
	border: 1px solid transparent;
	padding: 20px 16px 3px 16px;
	height: 58px;
	transition: all 0.2s ease;
	border-radius: 6px;
	font-size: 1.125rem;
	font-weight: normal;
	color: #495057;

	&:hover {
		border-bottom: 1px solid #C6C6C6;
	}
	&:focus {
		outline: none;
		border-bottom: 1px solid #0F62E6;
	}

	&.error {
		border-bottom: 2px solid #FF6600;
	}
}

.custom-float-label {
	top: 1.2em;
	left: 1.2em;
	font-size: 0.8em;
}
</style>
