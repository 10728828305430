import { callApi } from '@/services/WebApi';




export const getUserAccess = (id) => {
  return callApi('GET', `access/user/${id}`);
};

export const getCompanyUserAccess = (id) => {
  return callApi('GET', `access/company/${id}`);
};


export const inviteUsers = (companyId, baseRole, emails) => {
  const data = {companyId:companyId, baseRole:baseRole, emails:emails}
  return callApi('POST', `access/invite-users`, data);
};

export const checkInviteUsersExist = (companyId, emails) => {
  const data = {companyId:companyId, emails:emails}
  return callApi('POST', `access/invite-users/check-email-exist`, data);
};


export const verifyInvite = (token) => {
  const data = {token:token}
  return callApi('POST', `access/verify-invite`, data);
};

export const removeAccess = (companyId, userId) => {
  const data = {companyId:companyId, userId:userId}
  return callApi('DELETE', `access`, data);
};