<template>
  <main class="site-wrapper">
    <div class="section-img"><NavLogoWhite class="logo"/></div>
    <div class="section-blank"><NavLogoWhite class="logo logo-top-left"/>
      <router-view />
      <div class="footer"><Footer class="footer-text-color"/></div>
    </div>
  </main>
</template>


<script setup>
import { onMounted, ref, computed } from "vue";
import NavLogo from "@/views/_layout/_NavLogo.vue";
import NavLogoWhite from "./_NavLogoWhite.vue";
import Footer from "@/views/_layout/_Footer.vue";
import router from "@/router";
import Constant from "@/helpers/Constant";
import { useStoreSite } from '@/stores/store.site'

onMounted(async () => {
  // await nextTick();
  const storeSite = useStoreSite()
  storeSite.setTitle(Constant.SITE_TITLE);
  const metadata = [{ name: "description", content: Constant.SITE_DESC }];
  storeSite.setMetadata(metadata);  
});
</script>

<style scoped lang="scss">
.site-wrapper {
  display: flex;
  height: 100%;
  background-color: white;
  .section-img {
    display: none;
    // background-image: url("@/assets/imgs/landing-circle-text.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .section-blank {
    // background-image: url("@/assets/imgs/landing-circle-text.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .footer {
      position: absolute;
      bottom: 0;
      width: 100%;
      & .footer-text-color {
        color: #cccccc;
      }
    }
  }
}

.logo {
  position: absolute;
  top: 10px;
  &.logo-top-left {
    left: 0;
  }
}

@media screen and (min-width: $break-large) {
.site-wrapper {
    .section-img {
      display: block;
      width: 50%;
    }
    .section-blank {
      background-image: none;
      width: 50%;

      .footer {
        & .footer-text-color {
          color: #333333;
        }
      }
    }
  }
  .logo.logo-top-left {
    display: none;
  }
}
</style>
