<script setup>
import InputGroup from "primevue/inputgroup"
import InputNumber from "primevue/inputnumber"
import Dropdown from "primevue/dropdown"
import { countriesData } from "@/assets/json/all-country.js"

const currency = defineModel('currency');
const amount = defineModel('amount');

/*** utils function ***/
const getCountryCodeByCurrency = (currency) => {
	const country = countriesData.find((country) => country.currency == currency)
	return country.code
}
</script>

<template>
	<InputGroup>
		<InputNumber v-model="amount"  mode="currency" currency="USD" placeholder="Enter Amount" />
		<Dropdown v-model="currency"  placeholder=" Currency" :options="countriesData" optionLabel="currency" optionValue="currency">
			<template #value="slotProps">
				<div v-if="slotProps.value" class="flex align-items-center gap-2">
					<div>
						<flag class="border-circle" :iso="getCountryCodeByCurrency(slotProps.value)" />
					</div>
					<span>{{ slotProps.value }}</span>
				</div>
				<span v-else>
					{{ slotProps.placeholder }}
				</span>
			</template>
			<template #option="slotProps">
				<div class="flex align-items-center gap-2">
					<flag :iso="slotProps.option.code" class="border-circle" />
					<span>{{ slotProps.option.currency }}</span>
				</div>
			</template>
		</Dropdown>
	</InputGroup>
</template>

<style scoped lang="scss">
:deep(.p-inputtext) {
	border-top: 1px solid transparent;
	border-right: 1px solid transparent;
	border-left: 1px solid transparent;
}

:deep(.p-dropdown) {
	border-top: 1px solid transparent;
	border-right: 1px solid transparent;
	border-left: 1px solid transparent;
}
</style>
