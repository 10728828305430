import { defineStore  } from 'pinia'
import * as accessService from '@/services/service.access';
import router from '@/router';
import _ from 'lodash';
import ShareFunc from "@/helpers/ShareFunc";
import * as Account from '@/models/model.account';
import { customJoi as Joi, customJoi, prepareJoiErrorArr } from '@/models/customJoi';
import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';
import Constant from "@/helpers/Constant";
import env from "@/helpers/Environment";
import qs from 'qs';
import Utils from '@/helpers/Utils';
import { useStoreCompany } from "@/stores/store.company"

// user data from login api
const objUser = { userId:null, firstName:null, lastName:null, mobileFull:null, idToken:null, accessToken:null, refreshToken:null };
const objCompany = {  organizationId: null, registeredNumber: null, companyName: null, companyType: null, companyStatus: null, publishStatus: null, companySetupDetail: null, };
const objState = { 
  accessArr: [],
  companyArr: [],
  loading:false
};

export const useStoreAccess = defineStore('access', {
  state: () => ( _.cloneDeep(objState) ),
  //state: () => ({user:{firstName: "", lastName: "", salutation:"", newUser:true}}),
  getters: {
    
  },
  actions: {
    reset() {
      this.accessArr = [];
      this.companyArr = [];
    },
    async getUserAccess (userId) {
      return accessService.getUserAccess(userId)
        .then((res) => {
          const arr = res.data;
          const o = prepareCompanyList(arr);
          const s = useStoreCompany();
          s.initCompanyArrFromAccess(o) // will show loading companies
          this.companyArr = o;
          return true;
        })
        .catch((err) => {
          throw err;
        });
    },
    async getCompanyUserAccess (companyId) {
      return accessService.getCompanyUserAccess(companyId)
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },    
    async inviteUsers (companyId, baseRole, emails) {
      return accessService.inviteUsers(companyId, baseRole, emails)
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },    
    async checkInviteUsersExist (companyId, emails) {
      //console.log("checkInviteUsersExist", companyId, emails)
      return accessService.checkInviteUsersExist(companyId, emails)
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },
    async verifyInvite (token) {
      return accessService.verifyInvite(token)
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },    

    async removeAccess (companyId, userId) {
      return accessService.removeAccess(companyId, userId)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
    },


    // async updateAccess (data) {
    //   return accountService.updateProfile(data)
    //     .then((res) => {
    //       return res.data;
    //     })
    //     .catch((err) => {
    //       throw err;
    //     });
    // },

  },
});



// get company list from array
const prepareCompanyList = (arr) => {
  let o = [];
  for (let i in arr) {
    const temp = arr[i].companyData;
    if (!temp) {
      continue;
    }
    let c = {};


    c.organizationId = temp.organizationId;
    c.id = temp.id;
    c.companyName = temp.companyName;
    c.registeredNumber = temp.registeredNumber;    
    c.companyType = temp.companyType;
    c.companyStatus = temp.companyStatus;
    c.publishStatus = temp.publishStatus;
    if (c.publishStatus == "draft") {
      c.companyName = temp.companySetupDetail.companyName[0]
    }
    c.baseRole = arr[i].baseRole;
    c.moduleAccess = arr[i].moduleAccess;
    c.activatedModule = temp.activatedModule;

    //console.log("temp", arr[i]);
    o.push(c);
  }
  //console.log("arr", arr);          
  return o;
}


