<script setup>
import { reactive, watch, onMounted, computed, getCurrentInstance, toRaw } from 'vue';
import Dropzone from "dropzone";
import 'dropzone/dist/dropzone.css';
import * as siteService from '@/services/service.site';
import Card from 'primevue/card';
import shareFunc from '@/helpers/ShareFunc.js';
//import iconFile from '@/assets/icons/file-icon-txt-small.png';
import { useI18n } from 'vue-i18n';
import {addTranslation, getAllTranslation} from '@/intl/generate.js';

const MAX_FILE_SIZE = 5 * 1024 * 1024;

const { t:tt, locale } = useI18n();
//locale.value = 'cn';
const t = (txt) => {
	addTranslation(txt);
	return tt(txt)
}



const state = reactive({
    loading: false,
    files: [],
});

const props = defineProps({
    pDzId: { type: String, default:"my-dz-form" },
    pFiles: { type: Array, default:[] },
    pReadOnly: { type: Boolean, default:false },
  })

let myDropzone = null;

const emit = defineEmits(["updateFiles","error:updateFiles"]);

watch(state, () => {
    // console.log('state', state);
    emit("updateFiles", toRaw(state.files))
});

onMounted(()=>{
    //Dropzone.autoDiscover = false;
    // const ctx = getCurrentInstance().ctx;
    // console.log(">>>>>>>", ctx)
    state.files = props.pFiles;

    if (!props.pReadOnly) {
        myDropzone = new Dropzone(`#${props.pDzId}`, {
            url: '/',
            dictDefaultMessage: "ccb",
            uploadMultiple: true,
            acceptedFiles: 'image/*, application/pdf',
            previewContainer: '.files-preview',
            addRemoveLinks: true,
            // thumbnailWidth: 512,
            // thumbnailHeight: 512,
            thumbnailMethod: "contain",
            createImageThumbnails: false,
            disablePreviews: true,
        });
        myDropzone.on("addedfile", onAddedFile);
        myDropzone.on("thumbnail", (file, dataURL) => {
        console.log(`thumbnail: ${file}, ${dataURL}`);
        });
        // myDropzone.on('removedfile', onRemovedFile);
        myDropzone.on("complete", (file)=>{
            onRemovedFile(file,myDropzone)
        });
    }
});

const onAddedFile = async (file) => {
    console.log("onAddedFile", file);
    try {
        if(file.size >= MAX_FILE_SIZE){
            throw Error("Please upload files no larger than 5 MB.")
        }
        state.loading = true;
        const res = await siteService.uploadS3Ext(file, file.type);
        state.loading = false;
        //console.log("uploadS3 res", res);
        //myDropzone.emit("thumbnail", file, iconFile);

        // Ability to preview the uploaded document
        const fileBlob = await shareFunc.convertFileToBlob(file);
        const objectURL = window.URL.createObjectURL(fileBlob);

        state.files.push({ 
            key: res.key ,
            name:file.upload.filename,
            objectURL:file.dataURL,
            uuid:file.upload.uuid,
            preview: objectURL,
        });
    } catch (err) {
        state.loading = false;
        console.log("onAddedFile err", err);
        emit("error:updateFiles", toRaw(err))
    }
};

const onRemovedFile = (file, dropzone) => {
    // console.log("onRemovedFile")
    dropzone.removeFile(file);
    // state.files = state.files.filter((document) => document.file.upload.uuid !== file.upload.uuid);
};

const handleClickDeleteFile = (index) => {
    state.files.splice(index,1)
}

const handlePreviewFile = (index) => {
    window.open(state.files[index].preview);
}

const computeMaxHeight = computed(()=>document.querySelectorAll(".dropzone")[0].clientHeight+"px")
</script>

<template>
<div class="w-full" :key="pDzId">
    <div class="">
        <!-- {{pDzId}} -->
        <div class="dropzone " :id="pDzId">

            
            <template v-if="state.files.length>0">
                <Card v-for="(file, index) in state.files" :key="index" class="shadow-none bg-yellow-100 border-grey-custom border-round-lg mb-2" :pt="{
                    content:{class:'p-0 text-left flex justify-content-between align-items-center'},
                    body:{class:'px-3 py-2 '}
                }">
                    <template #content>
                        
                        <div class="flex align-items-center">
                            <!-- <img role="presentation" :alt="file.name" :src="file.objectURL" width="100" height="50" class="shadow-2" /> -->
                            <i class="pi-file pi text-lg" ></i>
                            <span class="ml-4">{{ file.name }}</span>
                        </div>
                        <div class="flex flex-row align-items-end">
                            <div v-if="!pReadOnly" class="mr-2" @click="handlePreviewFile(index)">
                                <i class="pi pi-eye text-md cursor-pointer hover:text-500 "></i>
                            </div>
                            <div v-if="!pReadOnly" class="delete-file " @click="handleClickDeleteFile(index)">
                                <i class="pi pi-times text-md cursor-pointer hover:text-500 "></i>
                            </div>
                        </div>
                    </template>
                </Card>
            </template>
            <template v-else> </template>

                <div v-if="!pReadOnly" class="instruction flex align-items-center justify-content-center pointer-events-none" :class="state.files.length>0?'short':''">
                    <CustomLoader  v-if="state.loading"  size="small" />
                    <i v-else class="pi-upload pi text-500"></i>
                    <span class="ml-2 text-500 text-sm"> {{ t('Drag and drop files here') }} </span>
                </div>

           


            
            <div class="dz-default dz-message">
            </div>
            
        </div>
    </div>
    <!-- --> 
    
    <!-- <div class="">
        <div v-if="state.files.length > 0" class="files overflow-auto " :style="`max-height:${computeMaxHeight}`">
            <Card v-for="(file, index) in state.files" :key="index" class="shadow-none border-grey-custom border-round-lg mb-2" :pt="{
                content:{class:'p-0 text-left flex justify-content-between align-items-center'},
                body:{class:'px-3 py-2 '}
            }">
                <template #content>
                    
                    <div class="flex align-items-center">
                         <i class="pi-file pi text-lg" ></i>
                        <span class="ml-4">{{ file.name }}</span>
                    </div>
                    <div class="delete-file" @click="handleClickDeleteFile(index)">
                        <i class="pi pi-times text-md"></i>
                    </div>
                </template>
            </Card>
        </div>
    </div> -->
    <!-- <img role="presentation" :alt="file.name" :src="file.objectURL" width="100" height="50" class="shadow-2" /> -->
                       
</div>
</template>

<style lang="scss" scoped>
.dropzone {
    //min-height: 100px;
    //height: 150px;
    border:1px dashed #e6e6e6!important;
    background-color:#f7f7f7;
    .dz-message {
        //margin:1em 0;
        margin: 0;
    }
    // .dz-preview.dz-image-preview  {
    //     background: #ffffff66;
    // }
    .instruction { min-height: 110px; 
        &.short { min-height: unset; margin-top:1em}
    }
}


.error .dropzone {
    //min-height: 100px;
    border:1px dashed #FF6600 !important;
}

.files {
    padding-right:0.5rem;
    /* width */
    &::-webkit-scrollbar {
        width: 7px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        // background: #f1f1f1; 
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
    background: #c9c9c9; 
    border-radius:8px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
    // background: #555; 
    }
}

</style>
