<script setup>
import { reactive, toRef, ref, onMounted, onBeforeMount, onBeforeUnmount, nextTick, toRaw, computed, onUpdated } from "vue"
import { useStoreSite } from "@/stores/store.site";
import Utils from '@/helpers/Utils';
import _ from "lodash";
import { useI18n } from 'vue-i18n'
import {addTranslation} from '@/intl/generate.js';

const storeSite = useStoreSite();
const rLanguage = ref("en")
const languageTypes = [{name:"English", value:"en", display:"EN"}, {name:"中文", value:"cn", display:"中文"}]

onMounted(async () => {
  rLanguage.value = storeSite.locale;
	//locale.value = storeSite.locale;
})

const onChangeLocale = () => {
  storeSite.changeLocale(rLanguage.value);
}

const changeLocale = (lang) => {
	storeSite.changeLocale(lang);
	//locale.value = lang;
}

const cDropdownValue = computed (()=>{
  const v = rLanguage.value;
  const res = _.find(languageTypes, {value: v});
  return res;
})

const menu = ref();
const items = ref([{
        label: 'Language / 语言',
        items: [{
                label: 'English', command: () => { rLanguage.value = "en"; changeLocale("en")},
                //icon: 'pi pi-refresh'                
            }, {
                label: '中文', command: () => { rLanguage.value = "cn"; changeLocale("cn")},
                //icon: 'pi pi-upload'                
            }]
      }]);

const toggle = (event) => {
    menu.value.toggle(event);
};

</script>

<template>
    <!-- <div class="flex flex-column align-items-center gap-2 mb-1">
			<div class="flex gap-1">
				<i class="pi pi-globe"></i><span class="font-body2">Language / 语言</span>
			</div>
			<div class="flex gap-3">
				<a href="#" @click="changeLocale('en')">English</a>
				<a href="#" @click="changeLocale('cn')">中文</a>
			</div> 
		</div>

    <Dropdown v-model="rLanguage" :options="languageTypes" optionLabel="name" optionValue="value" class="w-full md:w-14rem" @change="onChangeLocale">
      <template #value="slotProps">
        <i class="pi pi-globe"></i> {{ cDropdownValue.name }}
      </template>
    </Dropdown> 
  
  
      <Button outlined type="button" icon="pi pi-globe" @click="toggle" aria-haspopup="true" aria-controls="overlay_menu" :label="cDropdownValue.display" />
    -->

    <CustomButton @click="toggle" aria-haspopup="true" aria-controls="overlay_menu" noborder >
      <i class="pi pi-globe mr-2"></i>{{cDropdownValue.display}}
    </CustomButton>
    
    <Menu ref="menu" id="overlay_menu" :model="items" :popup="true" />


</template>

<style scoped lang="scss">
.tag-custom {
  background-color: #f59937;
  color: white;
  border-radius: 3px;
  padding: 3px 10px;
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
}
</style>