import { useGtm } from '@gtm-support/vue-gtm';
// import env from "@/helpers/Environment";
// import qs from 'qs';
import Cookies from 'js-cookie';

const generateRandomString = (length) => {
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for(let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
}
const generateCodeChallenge = async (codeVerifier) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(codeVerifier);

  return window.crypto.subtle.digest('SHA-256', data).then(hash => {
    let base64 = btoa(String.fromCharCode(...new Uint8Array(hash)));
    return base64.replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
  });
}

const documentDownload = (getURL, fileName)=>{
  fetch(getURL)
    .then(resp => resp.status === 200 ? resp.blob() : Promise.reject('Error downloadFile'))
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
      .catch(() => {});
}

const documentPreview = (getURL, fileName)=>{
window.open(getURL)
}

const convertFileToBlob = async (file) => {
return new Promise((res,rej)=>{
  const reader = new FileReader();
  reader.onload = function(event) {
    // The result is a Blob object
    const blob = new Blob([event.target.result], { type: file.type });

    // Now you can use the 'blob' as needed, e.g., upload it to a server
    // console.log("Converted Blob:", blob);
    res(blob)
  };

  reader.readAsArrayBuffer(file);
});
};


// place share functions project here
export default {
  track: (event, target, targetProperty,value, action="click") => {
    /*
    const gtm = useGtm();
    gtm.trackEvent({
      event: event,
      category: target,
      action: action,
      label: targetProperty,
      value: value,
      noninteraction: false,
    });
    */
  },

  //redirect after PKCE generation

  //PKCE
  generatePKCE:async (url)=>{
    //const state = Math.random().toString(36).substring(2); // Generate a new state value
    const codeVerifier = generateRandomString(50); // Generate a new code verifier
    const codeChallenge = await generateCodeChallenge(codeVerifier); // Generate the code challenge
    //store cookie for code_challenge later on auth-callback
    Cookies.set(`mpkce`, codeVerifier, {expires: 1})
    // //console.log(codeChallenge)
    const refineURL = url.replace(/\{code\}/, codeChallenge).replace(/\{chmt\}/, "S256")//S256
    // //console.log(refineURL)
    return refineURL
  },

  generateRedirect (url, companyId) {
    const refineURL = url.replace(/\{companyId\}/, companyId)
    return refineURL;
  },
  documentDownload,
  convertFileToBlob,
};




    // https://account.meyzer.xyz/auth
    //   ?client_id=central&
    //   response_type=code&
    //   redirect_uri=https://localhost:3001/auth-callback&
    //   code_challenge=CODE_CHALLENGE&
    //   code_challenge_method=256&
    //   scope=openid%20profile%20permissions