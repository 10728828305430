<template>
	<div class="custom-input-number">
		<span class="p-float-label">
			<InputNumber :id="pLabel" v-model="model" :style="style" :disabled="disabled" @update:modelValue="updateValue"/>
			<!-- mode="currency" currency="MYR" -->
			<label :for="pLabel">{{ pLabel }}</label>
		</span>
	</div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
	modelValue: { type: [String, Number], default: 0 },
	pLabel: { type: [String, Number], default: "" },
	disabled: { type: Boolean, default: false },
	style: { type:Object, default: {} },
})

const emit = defineEmits(["update:modelValue"])

const model = computed({
	get() {
		return props.modelValue
	},
	set(value) {
		return emit("update:modelValue", value)
	},
})

const updateValue = () => {}
</script>

<style lang="scss">
.custom-float-label {
	top: 1.2em;
	left: 1.2em;
	font-size: 0.8em;
}
.custom-input-number {
	.p-float-label {
		.p-inputtext {
			background-color: #F5FBFF;
			// font-size: 1.4rem;
			font-size: 1.125rem;
			border: none;
			border-radius: 5;
			height: 58px;
			padding: 20px 16px 3px 16px;
			width: 100%;
			border-bottom: 1px solid #ECECEC;
			text-overflow: ellipsis;
			&:hover {
				border-bottom: 1px solid #C6C6C6;
			}
			&:focus {
				outline: none;
				border-bottom: 1px solid #0F62E6;
			}
			&:enabled:focus {
				// box-shadow: 0 0 0 0.02rem white;
			}
			&:focus + label {
				@extend .custom-float-label;
			}
		}
		.p-inputwrapper-focus ~ label{
			top:30%;
			padding-left:8px ;
			padding-right:8px;
			font-size:0.8em;
		}
		.p-inputwrapper-filled ~ label{
			top:30%;
			padding-left:8px ;
			padding-right:8px;
			font-size:0.8em;
		}
		
		label {
			// top:-10px;
			font-size: 1rem;
			font-weight: 400;
		}

		//.p-filled + label {
			//@extend .custom-float-label;
		//}
		.p-inputwrapper-filled ~ label {
			top: 1rem;
		}
	}
	&.error .p-float-label .p-inputtext {
		border-bottom: 2px solid #FF6600;
	}
}
</style>
