<!-- <script setup>
import Button from "primevue/button"
</script>

<template>
	<Button rounded><slot></slot></Button>
</template>

<style scoped lang="scss">
.p-button {
	color: #1f1f30;
	font-size: 0.875rem;
	background-color: $primaryColor;
	border: 1px solid $primaryColor;
	font-weight: 500;

	&:enabled:hover {
		background-color: mix(black, #ffdb00, 10%);
		border: 1px solid mix(black, #ffdb00, 10%);
	}

	&.p-button-outlined {
		color: #1f1f30;
		border: 1px solid $primaryColor;

		&:enabled:hover {
		background-color: mix(white, #ffdb00, 90%);
			color: #1f1f30;
		border: 1px solid $primaryColor;

		}
	}

	&.p-button-text {
		padding: 0;
		color: #116dff;
	}
}
</style> -->

<template>
	<Button class="btn-custom" :class="btnStyle" :disabled="disabled || loading">
		<slot />
	</Button>
</template>

<script setup>
import { computed } from "vue"
import CustomLoader from "@/components/CustomLoader.vue"

const props = defineProps({
	size: { type: String, default: "" },
	outlined: { type: Boolean, default: false },
	noborder: { type: Boolean, default: false },
	disabled: { type: Boolean, default: false },
	text: { type: Boolean, default: false },
	iconStyle: { type: Object, default: {} },
	loading: { type: Boolean, default: false },
	rectangle: { type: Boolean, default: false },
	square: { type: Boolean, default: false },
	active: { type: Boolean, default: false },
})

const btnStyle = computed(() => ({
	"btn-big": props.size === "big",
	"btn-small": props.size === "small",
	"btn-outlined": props.outlined,
	"btn-no-border": props.noborder,	
	"btn-disabled": props.disabled,
	"btn-text": props.text,
	"btn-loading": props.loading,
	"btn-rectangle": props.rectangle,
	"btn-square": props.square,
	"btn-active": props.active,
	"btn-icon": props.icon && !props.outlined,
}))
</script>

<style scoped lang="scss">
.btn-custom {
	background-color: $primaryColor;
	font-size: 0.875rem;
	//font-weight: 600;
	color: var(--theme-color, #000624);
	//border-radius: 100px;
	border-radius: 6px;
	border: 1px solid $primaryColor;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	transition: background-color 0.2s ease;
	cursor: pointer;

	&.btn-square {
		border-radius: 6px;
	}

	&.btn-big {
		padding: 0.5625rem 1.875rem;
	}

	&.btn-small {
		padding: 0.375rem 1.125rem;
		min-width: 147px;
	}

	&.btn-outlined {
		background-color: white;
		color: #000624;
		display: flex;
	}
	&.btn-no-border {
		border: 0;
		background-color: white;
	}

	&.btn-active {
		background-color: $primaryColor;
		&:hover {
			background-color: $primaryColor;
		}
	}

	&:hover {
		background-color: #FFE79D;
		color: #000624;
	}

	&.btn-rectangle {
		border: 1px solid #E6E6E6;
		border-radius: 10px;
		padding: 1.5rem;
		background-color: transparent;
		color: #595D70;
		width: 100%;
		font-weight: 400;
		justify-content: start;

		&:hover {
			background-color: #FFE79D;
			color: #595D70;
		}

		&.btn-active {
			background-color: #FFC309;
			color: #000624;
		}
	}

	&.btn-icon {
		// color: #595D70;
		color: $primaryColor;
		background-color: transparent;
		border: 1px solid transparent;
		padding: 0;
	}

	&.btn-text {
		color: #000624;
		background-color: transparent;
		border: 1px solid #FFC309;

		&:hover {
			background-color: #FFE79D;
		}
	}

	&.btn-loading {
		opacity: 0.8;
		cursor: default;
		pointer-events: none;
	}

	&.btn-disabled {
		//background-color: #DDE1DB;
		background-color: #ccc;
		color: #999999;
		//color: red !important;
		border: 1px solid #DDE1DB;
		pointer-events: none;
	}
}
</style>
