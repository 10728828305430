<script setup>
import { computed, ref, watch } from "vue"
import { storeToRefs } from "pinia"
import NavMenuMobile from "./_NavMenuMobile.vue"
import Navbar from "@/views/_layout/_Navbar.vue"
import NavSidebar from "@/views/_layout/_NavSideBar.vue"
import LayoutMainCorpsecOnboardingSidebar from "./Layout.Main.CorpsecOnboardingSidebar.vue"
import LayoutMainDigitalFinanceOnboardingSidebar from "./Layout.Main.DigitalFinanceOnboardingSidebar.vue"
import CompanyBlock from "@/components/CompanyBlock.vue"
import Footer from "@/views/_layout/_Footer.vue"
import InputText from "primevue/inputtext"
import Sidebar from "primevue/sidebar"
import { useStoreSite } from "@/stores/store.site"
import { useStoreCompany } from "@/stores/store.company"
import env from "@/helpers/Environment"

const storeSite = useStoreSite()
const storeCompany = useStoreCompany()

</script>

<template>
	<div class="body-holder flex flex-row">
		<Navbar class="fixed top-0 left-0" />
		<div class="flex-none nav-side" :class="[{ 'sidebar-expand': storeSite.showNavSideBarFlag }]">
			<div class="nav-side-fixed-bg"></div>
			<NavSidebar class="nav-side-bar" id="sidebar" />
			<Sidebar v-if="storeSite.isMobile"  v-model:visible="storeSite.showNavSideBarFlag" header=" "  :showCloseIcon="false" class="w-15rem">
				<NavSidebar class="nav-side-bar" id="sidebar" />
			</Sidebar>
		</div>

		<main class="flex-grow-1 bg-white content-spacing-adjustment">
			<div class="container-small">
				<router-view v-slot="{ Component }">
					<component :is="Component" />
				</router-view>
			</div>
		</main>
	</div>

	<LayoutMainCorpsecOnboardingSidebar />
	<LayoutMainDigitalFinanceOnboardingSidebar />

	<Footer class="" />
</template>

<style lang="scss" scoped>
.content-spacing-adjustment {
  padding-top: calc($navbar-height + 1.5rem);
  padding-left:1rem;
  padding-right:1rem;
	padding-bottom:30px;

  @media screen and (min-width: $break-medium ) {
    padding-left:40px;
    padding-right:40px;
  }
}

.nav-side-fixed-bg {
	position: fixed;
	background-color: var(--sidebar-bg-color, #FCFCFD);
	left:0;
	min-height: 100vh;
	// width: calc((100vw - 1650px) / 2);
	width: calc((100vw - 1100px) / 2);
}

.body-holder {
	max-width: 1650px;
	min-height: calc(100vh - 31px);
	margin: 0 auto;
	background-color: var(--sidebar-bg-color, #fcfcfd);
	// background-color: linear-gradient(270deg, rgb(249, 250, 252), white 100px);
	// background-image: linear-gradient(270deg, rgb(249, 250, 252), white 100px);

	//stripe
	// background-image: linear-gradient(270deg, rgb(249, 250, 252), white 100px);

	.nav-side {
		position: relative;
		width: 264px;
		// padding: 4.25rem;
    padding-left:1.25rem;
    padding-right:1.25rem;
		padding-top: 10px;
		transition: width 0.2s ease, min-width 0.2s ease, margin-left 0.2s ease;
		//background-color: rgb(255, 78, 78);

		.nav-side-bar {
			top: calc($navbar-height + 24px);
			position: sticky;
			overscroll-behavior: none;
		}

		@media screen and (max-width: 820px) {
			margin-left: -264px;
		}
	}

	.sidebar-expand {
		// margin-left: 0;
		position: fixed;
		// background: var(--sidebar-bg-color);
		// background-color: linear-gradient(0deg, rgba(252,253,254,1) 0%, rgba(250,251,252,1) 100%);
	// background: linear-gradient(90deg, rgba(252,253,254,1) 0%, rgba(250,251,252,1) 100%);

		min-height: 100%;
		// width:100%;
		z-index: 2;

		@media screen and (min-width: 821px) {
			width: 264px;
			position: relative;
		}
	}

	.content-side {
		// display: flex;
		background-color: #fff;
	}
}

.wrapper {
	min-height: calc(100vh - 100px);
}

.content {
	&.content-shrink {
		margin-left: 314px;
		transition: all 0.2s ease;
	}
	&.content-expand {
		margin-left: 0;
		transition: all 0.2s ease;
	}

	@media (max-width: $break-small) {
		margin-left: 0;
		//padding-top: 100px;
		padding-top: 1.8rem;
	}
}
</style>
