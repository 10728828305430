<script setup>
import { computed, ref, onBeforeMount, onMounted, watch, reactive } from 'vue';
import { storeToRefs } from 'pinia';
import Sidebar from 'primevue/sidebar';
import { useStoreSite } from '@/stores/store.site';
import { useStoreForm } from '@/stores/store.form';
import env from "@/helpers/Environment"
import router from '@/router';
import Utils from '@/helpers/utils';
import { useConfirm } from "primevue/useconfirm";
import { showToast } from "@/components/Notification";
import DashboardWelcome from "@/views/Dashboard.Welcome.vue"
import { useDialog } from "primevue/usedialog"
import _ from "lodash";

const storeSite = useStoreSite();
const storeForm = useStoreForm()
const { showDigitalFinanceOnboardingSideBarFlag } = storeToRefs(storeSite);
const confirm = useConfirm();

const rData = reactive({
  loading: false,
  disabledApplyBtnFlag: false,
  forms: []
})

watch(showDigitalFinanceOnboardingSideBarFlag, ()=>{
  console.log("showDigitalFinanceOnboardingSideBarFlag", showDigitalFinanceOnboardingSideBarFlag.value)
  if (showDigitalFinanceOnboardingSideBarFlag.value) {
    refreshData();
  }
})

const onClickApply = (item) => {
	router.push("/forms/digital-finance-application");
}

onMounted(async () => {
  refreshData();
});

const refreshData = async () => {
  rData.loading = true;
  const o = await storeForm.getDigitalFinanceApplications();
  await Utils.sleep(200);
  rData.disabledApplyBtnFlag = false;
  let data = o.data;
  for (var i in data) {
    const a = data[i];
    // console.log(">>>", i, a, data[i] );
    if (a.formStatus == "draft" || a.formStatus == "pending" || a.formStatus == "processing" || a.formStatus == "accepted" ) {
      rData.disabledApplyBtnFlag = true;
    }
    //console.log("o.data", o.data[0].updatedAt);
    o.data = _.orderBy(o.data, 'updatedAt', 'desc');
  }
  rData.forms = o.data;
  rData.loading = false;
}

const onResumeForm = (formId) => {
  router.push("/forms/digital-finance-application/draft/"+formId);
}

const onViewForm = (formId) => {
  router.push("/forms/digital-finance-application/view/"+formId);
}

function onClickTrashApplication(formId) {
  confirm.require({
        message: 'Do you really want to proceed?',
        header: 'Withdraw Submission',
        icon: 'pi pi-exclamation-triangle text-lg',
        accept: async () => {
          try {
            await storeForm.withdrawById(formId);
            storeSite.showToast("Success", "Submission withdrawn", "success", 3000)
            await refreshData();
          } catch (e) {
            storeSite.showToast("Failed", err?.data?.validation || err?.message || "Error withdrawing", "error", 3000)
          }
          
          //rData.formData.personnels.splice(i, 1);
        },
        reject: () => {

        }
    });
}


const dialog = useDialog()
const dialogRef = ref("")

const onClickPreviewFintechVideo = () => {
	dialogRef.value = dialog.open(DashboardWelcome, {
		props: {
			header: "Welcome to MEYZER360",
			//header: " ",
			modal: true,
			closable: true,
			draggable: false,
			breakpoints: {
				"320px": "75vw",
				"640px": "90vw",
			},
		},
		emits: {
			onCancel: () => {
				dialogRef.value.close()
			},
		},
    data: {
        showFintechVideo: true,
    }
	})
}


</script>

<template>


  <Sidebar class="w-max" header=" " v-model:visible="showDigitalFinanceOnboardingSideBarFlag" position="right">
    <div class="side-bar-content">
      <div class="font-header2">MEYZER360 Digital Finance</div>
      <div class="font-body1 mb-2">Banking at your fingertips with our seamless digital finance services.</div>
      <Message class="info-message" icon="pi pi-info-circle text-lg">
        <div class="ml-2">Click <a href="#" @click.prevent="onClickPreviewFintechVideo">here</a> to learn more about MEYZER360 Digital Finance features</div>
			</Message>

      <!-- <div class="font-body1 mb-2">Click <a href="#" @click.prevent="onClickPreviewFintechVideo">here</a> to preview MEYZER Pay features</div> -->

<!-- 
<Message class="info-message " icon="pi pi-info-circle">
  <div class="font-body2">
  <div class="mb-2">This registration takes approximately 15 minutes. Please have your business and personal identification documents ready for upload during registration, including:</div>
  <ul class="ml-3">
    <li>ACRA/Bizfile Profile (dated no longer than 3 months ago)</li>
    <li>Company Memorandum and Articles of Association or Constitution (for Private Limited)</li>
    <li>Partnership Agreement (for LP/LLP)</li>
    <li>Proof of address (for foreigners)</li>
    <li>Details of your directors, shareholders, owners, partners, and authorised users (wherever applicable)</li>
    <li>ID copies of your directors</li>
  </ul>
</div>
</Message>
-->

      <div class="flex flex-column pt-4 m-2">
        <div v-if="rData.loading">
          <Skeleton class="w-full mb-2"></Skeleton>
          <Skeleton height=".5rem" class="mb-2"></Skeleton>
          <Skeleton height=".5rem" style="max-width: 15rem;"></Skeleton>
        </div>

        <div v-else >

          <div v-if="rData.disabledApplyBtnFlag" class="font-body5 font-bold">You have an application in progress</div>
          <template  v-for="n, i in rData.forms" >
            <!-- v-if="i==0" -->
            <div class="p-3 border-3 border-round-sm border-orange-50 mb-3 mt-1 surface-50 mb-5" v-if="i==0"  >
                <div class="flex justify-content-between">
                  <!-- <div class="font-body2">{{ Utils.formatDateDetailReadable(n.updatedAt) }}</div> -->
                  <div class="font-body2">{{ Utils.formatTimeFromNow(n.updatedAt) }} ago</div>
                  <div class="font-body2"><b>Status:</b> {{ Utils.formatTitleCase(n.formStatus) }}</div>

                </div>
                <div >{{ n.formData.step1.companyName }}</div>
                <div class="flex justify-content-between">
                  <CustomButton v-if="n.formStatus=='draft'" square class="mt-2" outlined icon="pi pi-pencil" @click="onResumeForm(n.id)" label="Resume form application"></CustomButton>
                  <CustomButton v-else square class="mt-2" outlined icon="pi pi-history" @click="onViewForm(n.id)" label="View Submitted Details"></CustomButton>
                  <i  v-if="n.formStatus=='draft'" class="pi pi-trash cursor-pointer text-yellow-500 hover:text-yellow-300 mt-2" @click="onClickTrashApplication(n.id)" />
                </div>
            </div>
          </template>


          <div class="p-4 border-1 border-round-sm border-300 mb-4 mt-2" >
          <!-- \style="border-radius: 4px; border: 1px solid #e1e2e6"> -->
            <div class="font-header2">Digital Finance Application</div>
            <div class="flex flex-column row-gap-3 mt-4">
              <div class="font-body2 ">Simplifying global transactions and finance. Bid farewell to bank queues, excessive paperwork, and unnecessary fees.</div>

                <CustomButton	class="yellow-btn w-max " @click="onClickApply" :disabled="rData.disabledApplyBtnFlag">
                  <span class="button-text">Apply Now </span>
                  <i class="pi pi-angle-right text-xs ml-2"></i>
                  <!-- https://corpsec.meyzer360.com/onboarding/1 -->
                </CustomButton>

            </div>
          </div>


        </div>
        
      </div>
    </div>
  </Sidebar>
</template>

<style lang="scss" scoped>

.side-bar-content {
  padding-left: 8px;
  padding-right: 8px;
  max-width: 505px !important;
}


</style>
