<template>
  <div class="inputtext-custom">
    <span class="p-float-label">
      <Textarea :id="pLabel" v-model="model" :disabled="disabled"
      :style="{
        minHeight:props.minHeight,
        maxHeight:props.maxHeight,
        minWidth:'100%',
        maxWidth:'100%'
      }"
        @update:modelValue="updateValue" :class="{'disabled-custom': disabled}"/>
      <label :for="pLabel">{{ pLabel }}</label>
    </span>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import Textarea from 'primevue/textarea';

const emit = defineEmits(['update:modelValue']);
const props = defineProps({ 
  modelValue: { type: [String, Number], default: ''},
  pLabel:  { type: [String, Number], default: ''},
  disabled:  { type: Boolean, default: false},
  maxHeight:  { type: [String], default: ''},
  minHeight:  { type: [String], default: ''},
})
const model = computed({
  get () { return props.modelValue },
  set (value) { return emit('update:modelValue', value) }
})
const updateValue = () => {
}
</script>

<style scoped lang="scss">
  .disabled-custom {
    // background-color: red;
    background-color: #edefec !important;
  }
</style>
