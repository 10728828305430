<script setup>
import Timeline from "primevue/timeline"

const props = defineProps(
    {
        events: {type: Array,default:[]},
        hideOpposite: {type:Boolean, default:false}
    }
)
</script>

<template>
	<Timeline :value="props.events" :class="{'opposite-display-none': props.hideOpposite}">
        <template #marker>
            <!-- <slot name="marker"></slot> -->
			<i class="pi pi-calendar p-1 border-circle p-2 timeline-icon"></i>
		</template>
		<template #content="slotProps">
            <slot name="content" :item="slotProps.item" :index="slotProps.index"></slot>
		</template>
        <template #opposite="slotProps">
            <slot name="opposite" :item="slotProps.item" :index="slotProps.index"></slot>
        </template>
	</Timeline>
</template>

<style scoped lang="scss">
.opposite-display-none {
	:deep(.p-timeline-event-opposite) {
		display: none;
	}
}

:deep(.p-timeline-event-opposite) {
    flex:initial;
    padding-left:0 !important;
}
</style>
