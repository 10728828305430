import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { createI18n } from 'vue-i18n'

import App from './App.vue';
import router from './router';

import VueLazyLoad from 'vue3-lazyload'
import env from "@/helpers/Environment";
import { createGtm } from '@gtm-support/vue-gtm';

//primevue components
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import SelectButton from 'primevue/selectbutton';
import Badge from 'primevue/badge';
import BadgeDirective from 'primevue/badgedirective';
import Breadcrumb from 'primevue/breadcrumb';
import Button from 'primevue/button';
import Textarea from "primevue/textarea"
import Card from 'primevue/card';
import Checkbox from 'primevue/checkbox';
import ConfirmPopup from 'primevue/confirmpopup';
import Divider from 'primevue/divider';
import Image from 'primevue/image';
import InputSwitch from 'primevue/inputswitch';
import InputNumber from 'primevue/inputnumber';
import PrimeVue from 'primevue/config';
import Carousel from 'primevue/carousel';
import DialogService from 'primevue/dialogservice';
import DynamicDialog from "primevue/dynamicdialog"
import Dropdown from 'primevue/dropdown';
import ConfirmationService from 'primevue/confirmationservice';
import Dialog from "primevue/dialog"
import Password from 'primevue/password';
import ProgressBar from 'primevue/progressbar';
// import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Skeleton from 'primevue/skeleton';
import TabMenu from 'primevue/tabmenu';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Tag from 'primevue/tag';
import TieredMenu from 'primevue/tieredmenu';
import Tooltip from 'primevue/tooltip';
import Chips from 'primevue/chips';
import RadioButton from 'primevue/radiobutton';
import Message from 'primevue/message';
import Menu from 'primevue/menu';
import MultiSelect from 'primevue/multiselect';
import SpeedDial from 'primevue/speeddial';
import Fieldset from 'primevue/fieldset';




//custom components
import CustomBreadcrumb from '@/components/CustomBreadcrumb.vue';
import CustomCard from '@/components/CustomCard.vue'
import CustomCarousel from '@/components/CustomCarousel.vue'
import CustomCarousel2 from '@/components/CustomCarousel2.vue'
import CustomButton from '@/components/CustomButton.vue'
import CustomSpinner2 from '@/components/CustomSpinner2.vue'
import CustomLoader from '@/components/CustomLoader.vue'
import CustomDivider from '@/components/CustomDivider.vue'
import CustomAvatar from "@/components/CustomAvatar.vue"
import CustomInputText from "@/components/CustomInputText.vue"
import CustomInputTextArea from "@/components/CustomInputTextArea.vue"
import CustomInputNumber from "@/components/CustomInputNumber.vue"
import CustomDropdown from "@/components/CustomDropdown.vue"
import CustomDropdownMobileCountry from "@/components/CustomDropdownMobileCountry.vue"
import CustomDropdownCountry from "@/components/CustomDropdownCountry.vue"
import DropzoneFileUpload from "@/components/DropzoneFileUpload.vue"
// todo: make dropzoneFileUpload support ext presign url endpoint
import DropzoneFileUploadExt from "@/components/DropzoneFileUploadExt.vue"
import Panel from 'primevue/panel';

import CustomDatePicker from "@/components/CustomDatePicker.vue";
import CustomInputGroupAmount from "@/components/CustomInputGroupAmount.vue";
import CustomTimeline from "@/components/CustomTimeline.vue";
import CustomDataTable from "@/components/CustomDataTable.vue";

//other components
import FlagIcon from 'vue-flag-icon'
import Toaster from "@meforma/vue-toaster";
import VueApexCharts from "vue3-apexcharts";
// import Datepicker from 'vue3-datepicker';
// import VueDatePicker from '@vuepic/vue-datepicker';
// import '@vuepic/vue-datepicker/dist/main.css'

import 'primeicons/primeicons.css';
import 'primevue/resources/themes/aura-light-blue/theme.css'
import '/node_modules/primeflex/primeflex.css'
import '@/styles/style.scss';


const gtm = createGtm({
  id: env.GTM,
  defer: true,
  compatibility: true,
  enabled: true,
});

import {en} from '@/intl/en.js';
import {cn} from '@/intl/cn.js';

export const i18n = createI18n({
  legacy: false,
  //globalInjection: true,
  locale: 'en',
  messages: {
    en: en,
    cn: cn,
  }
})

export const app = createApp(App);
  app.use(router)
  app.use(gtm)
  app.use(createPinia())
  app.use(i18n)
  // app.use(createI18n({
  //   locale: 'ja', // set locale
  //   fallbackLocale: 'en', // set fallback locale
  //   messages: {
  //     en: en,
  //     cn: cn,
  //   }
  // }))
  app.use(PrimeVue)
  app.use(FlagIcon)

  app.component('Accordion', Accordion)
  app.component('Panel', Panel)
  app.component('Fieldset', Fieldset)
  app.component('AccordionTab', AccordionTab)
  app.component('Card', Card)
  app.component('Checkbox', Checkbox)
  app.component('ConfirmPopup', ConfirmPopup)
  app.component('Badge', Badge)
  app.component('Breadcrumb', Breadcrumb)
  app.component('Button', Button)
  app.component('Divider', Divider)
  app.component('Image', Image)
  app.component('InputSwitch', InputSwitch)
  app.component('InputNumber', InputNumber)
  app.component('Carousel', Carousel)
  app.component('Dialog', Dialog)
  app.component('DynamicDialog', DynamicDialog)
  app.component('Dropdown', Dropdown)
  app.component('SelectButton',SelectButton )
  app.component('Textarea',Textarea )
  app.component('Password',Password )
  app.component('ProgressBar',ProgressBar )
  
  // app.component('Toast',Toast )
  app.component('Tag',Tag )
  app.component('Chips',Chips )
  app.component('Message',Message )
  app.component('Menu',Menu )  
  app.component('MultiSelect',MultiSelect )
  app.component('Skeleton',Skeleton )
  app.component('TabMenu',TabMenu )
  app.component('TabView',TabView )
  app.component('TabPanel',TabPanel )
  app.component('TieredMenu',TieredMenu )
  app.component('RadioButton',RadioButton )  
  app.component('SpeedDial',SpeedDial )  
  
  // app.component('Datepicker',Datepicker )  
  // app.component('VueDatePicker',VueDatePicker )  
  

  app.component('CustomBreadcrumb', CustomBreadcrumb)
  app.component('CustomCard', CustomCard)
  app.component('CustomCarousel', CustomCarousel)
  app.component('CustomCarousel2', CustomCarousel2)
  app.component('CustomButton', CustomButton)
  app.component('CustomAvatar', CustomAvatar)
  app.component('CustomInputText', CustomInputText)
  app.component('CustomInputTextArea', CustomInputTextArea)
  app.component('CustomInputNumber', CustomInputNumber)
  //app.component('CustomInputTextMask', CustomInputTextMask)
  app.component('CustomDropdown', CustomDropdown)
  app.component('CustomDropdownCountry', CustomDropdownCountry)
  app.component('CustomDropdownMobileCountry', CustomDropdownMobileCountry)
  app.component('CustomSpinner2', CustomSpinner2)
  app.component('CustomLoader', CustomLoader)
  app.component('DropzoneFileUpload', DropzoneFileUpload)
  app.component('DropzoneFileUploadExt', DropzoneFileUploadExt)
  app.component('CustomDatePicker', CustomDatePicker)
  app.component('CustomInputGroupAmount', CustomInputGroupAmount)
  app.component('CustomTimeline', CustomTimeline)
  app.component('CustomDataTable', CustomDataTable)
  app.component('CustomDivider', CustomDivider)

  app.directive('badge', BadgeDirective)
  app.directive('tooltip', Tooltip)

  app.use(ConfirmationService)
  app.use(DialogService)
  app.use(ToastService)
  app.use(VueApexCharts)
  // app.use(Toaster)
  app.use(VueLazyLoad, {
    loading: 'https://stage-media.xxxxxxxxxxxx.com/media/sys/spinner.svg',
    error: 'https://stage-media.xxxxxxxxxxxx.com/media/sys/error.svg',
  })
  app.mount('#app');
