<script setup>
import { computed, ref, onBeforeMount, onMounted, watch,  } from 'vue';
import { storeToRefs } from 'pinia';
import NavMenuMobile from './_NavMenuMobile.vue';
import Navbar from '@/views/_layout/_Navbar.vue';
import NavSidebar from '@/views/_layout/_NavSideBar.vue';
import LayoutMainCorpsecOnboardingSidebar from './Layout.Main.CorpsecOnboardingSidebar.vue';
import LayoutMainDigitalFinanceOnboardingSidebar from './Layout.Main.DigitalFinanceOnboardingSidebar.vue';
import CompanyBlock from '@/components/CompanyBlock.vue';
import Footer from '@/views/_layout/_Footer.vue';
import InputText from 'primevue/inputtext';
import Sidebar from 'primevue/sidebar';
import { useStoreSite } from '@/stores/store.site';
import { useStoreCompany } from "@/stores/store.company";
import env from "@/helpers/Environment"

const storeSite = useStoreSite();
const storeCompany = useStoreCompany();


onBeforeMount(async () => {
});

onMounted( async()=>{
})

</script>

<template>
	<div class="site-wrapper">
    <Navbar class="fixed top-0 left-0" />
		<div class="content-wrapper flex">
			<main class="flex-1">
				<router-view v-slot="{ Component }">
					<keep-alive><component :is="Component" /></keep-alive>
				</router-view>
			</main>
		</div>
		<Footer class="" />
	</div>
</template>


<style lang="scss" scoped>
.site-wrapper {
	display: flex;
	flex-direction: column;
	min-height: 100%;
	background-color: #f4f7ff;
	nav {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 50px;
		padding: 0 27px;
		background-color: white;
	}
}
.content-wrapper {
	flex: 1;
	width: 87.18%;
	max-width: 1029px;
	margin: 0 auto;
	min-height: calc(100vh - 85px);
}
</style>
