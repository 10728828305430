const mode = import.meta.env.MODE

let baseUrl = "";
let oidcProvider = "";
let env = "local";
if (mode != "development") {
  env = mode;
} 
//oidc provider
//https://localhost:3002/v1/oidc/.well-known/openid-configuration
//https://api.central.meyzer.xyz/v1/oidc/.well-known/openid-configuration

//const env = "local";
//const env = "stage";
//const env = "prod";
console.log("*** system running in env:", env, " ***");

let frontendUrl = "",
  corpsecUrl = "",
  oidcRedirectUrl = "",
  apiUrl = "",
  apiCorpsecUrl = "",
  gtm = "",
  centralHomeUrl = '',
  corpsecHomeUrl = '',
  digitalBankingHomeUrl = '',
  accountSignOutUrl = "",
  formCorpsecUrl = "",
  corpsecFrontendCompanyIdUrl ="",
  formOnBoardingUrl="",
  v2onboardingUrl="",
  payrollUrl = '',
  accountingUrl = '',
  onBoardingUrl = '',
  oneErpUrl = "",
  oneErpSignUpUrl="";


  let demo_digital_bank =  "https://digitalbanking.meyzer360.com/dashboard";
  let demo_link_sales =  "https://sales.meyzer360.site/superapp/setup.php";

  let demo_link_payroll = "https://singroll.com/testing/meyzer_oauth";
  let demo_link_erp = "https://sales.meyzer360.site/meyzer_oauth?redirect=home"

  let demo_link_pos =  "https://sales.meyzer360.site/meyzer_oauth?redirect=pos";
  let demo_e_quotation = "https://sales.meyzer360.site/meyzer_oauth?redirect=e-quotation";
  let demo_link_srm = "https://sales.meyzer360.site/meyzer_oauth?redirect=suppliers"

  //let demo_link_crm =  "https://sales.meyzer360.site/leads/propal/list.php?mainmenu=commercial&leftmenu=leads&idmenu=32799&load=1";
  let demo_link_crm =  "https://aiosw.com/user2/meyzer_1sme/";
  
  
  let demo_link_ebrochure =  "https://sales.meyzer360.site/custom/brochure/view.php?mainmenu=commercial&leftmenu=brochures&idmenu=33060&load=1";
  let demo_link_arap = "https://sales.meyzer360.site/meyzer_oauth?redirect=arap"

  let demo_link_cfo = "https://www.meyzer360.com/cfo-as-a-service";
  let demo_link_cmo = "https://www.meyzer360.com/cmo-as-a-service";
  let demo_link_cio = "https://www.meyzer360.com/cio-as-a-service";
  let demo_link_cdo = "https://www.meyzer360.com/cdo-as-a-service";
  let demo_link_dpo = "https://www.meyzer360.com/dpo-as-a-service/";

  let landing_demo_link_erp = ""
  let landing_demo_link_payroll  = ""
  let landing_demo_link_pos = ""
  let landing_demo_link_crm = ""
  let landing_demo_link_ebrochure = ""
  let landing_demo_link_arap = ""

  let digitalBankCollectUrl = 'https://sales.meyzer360.site/meyzer_oauth?redirect=payment-link'




if (env == "local") {
//###### LOCAL ######
  frontendUrl = "https://localhost:3001";
  corpsecUrl = "https://localhost:3000";
  
  
  //local dev
  oidcRedirectUrl = "https://localhost:3001/auth-callback";
  oidcProvider = "https://localhost:3002";
  apiUrl = "https://localhost:3005/v1";

  //sit dev
  // oidcProvider = "https://account.meyzer360.rocks";
  // apiUrl = "https://api.central.meyzer360.rocks/v1";

  //stg dev
  // oidcProvider = "https://account.meyzer.xyz";
  // apiUrl = "https://api.central.meyzer.xyz/v1";

  apiCorpsecUrl = "https://localhost:3004/v1";


  gtm = "GTM-000000";
  
  centralHomeUrl = oidcProvider + '/auth?client_id=central&response_type=code&redirect_uri='+oidcRedirectUrl+'&code_challenge={code}&code_challenge_method={chmt}&scope=openid%20profile%20permissions';
  digitalBankingHomeUrl = 'https://digitalbanking.meyzer360.rocks/authenticate';
  corpsecHomeUrl = corpsecUrl + '/authenticate';
  accountSignOutUrl = oidcProvider+ '/session/end?client_id=central';

  //onBoardingUrl = 'https://corpsec.meyzer.xyz/onboarding';
  // formCorpsecUrl = "https://forms.meyzer360.com/corpsec";
  // formOnBoardingUrl = "https://forms.meyzer360.com/registerCompany"; 
  corpsecFrontendCompanyIdUrl ='https://localhost:3000/authenticate?companyId={companyId}'

  landing_demo_link_erp = "https://meyzer360.com/erp/"
  landing_demo_link_payroll = "https://meyzer360.com/hr-payroll-compliance/"
  landing_demo_link_pos = "https://meyzer360.com/cloud-pos/"
  landing_demo_link_crm = "https://meyzer360.com/crm/"
  landing_demo_link_ebrochure = "https://meyzer360.com/erp/"
  landing_demo_link_arap = "https://meyzer360.com/erp/"

} else if (env == "sit") {
//###### SIT ######
  frontendUrl = "https://central.meyzer360.rocks";
  corpsecUrl = "https://corpsec.meyzer360.rocks";
  oidcRedirectUrl = "https://central.meyzer360.rocks/auth-callback";
  oidcProvider = "https://account.meyzer360.rocks";
  apiUrl = "https://api.central.meyzer360.rocks/v1";
  apiCorpsecUrl = "https://api.corpsec.meyzer360.rocks/v1";
  gtm = "GTM-000000";

  centralHomeUrl = 'https://account.meyzer360.rocks/auth?client_id=central&response_type=code&redirect_uri='+oidcRedirectUrl+'&code_challenge={code}&code_challenge_method={chmt}&scope=openid%20profile%20permissions';
  corpsecHomeUrl = corpsecUrl + '/authenticate';
  digitalBankingHomeUrl = 'https://digitalbanking.meyzer360.rocks/authenticate';
  accountSignOutUrl = 'https://account.meyzer360.rocks/session/end?client_id=central';  
  
  //onBoardingUrl = 'https://corpsec.meyzer360.rocks/v2/onboarding';
  // formCorpsecUrl = "https://forms.meyzer360.com/corpsec";
  // formOnBoardingUrl = "https://forms.meyzer360.com/registerCompany"
  corpsecFrontendCompanyIdUrl ='https://corpsec.meyzer360.rocks/authenticate?companyId={companyId}'  

  landing_demo_link_erp = "https://meyzer360.com/erp/"
  landing_demo_link_payroll = "https://meyzer360.com/hr-payroll-compliance/"
  landing_demo_link_pos = "https://meyzer360.com/cloud-pos/"
  landing_demo_link_crm = "https://meyzer360.com/crm/"
  landing_demo_link_ebrochure = "https://meyzer360.com/erp/"
  landing_demo_link_arap = "https://meyzer360.com/erp/"

} else if (env == "stage") {
//###### STAGE ######
  frontendUrl = "https://central.meyzer.xyz";
  corpsecUrl = "https://corpsec.meyzer.xyz";
  oidcRedirectUrl = "https://central.meyzer.xyz/auth-callback";
  oidcProvider = "https://account.meyzer.xyz";
  apiUrl = "https://api.central.meyzer.xyz/v1";
  apiCorpsecUrl = "https://api.corpsec.meyzer.xyz/v1";
  gtm = "GTM-000000";

  centralHomeUrl = 'https://account.meyzer.xyz/auth?client_id=central&response_type=code&redirect_uri='+oidcRedirectUrl+'&code_challenge={code}&code_challenge_method={chmt}&scope=openid%20profile%20permissions';
  digitalBankingHomeUrl = 'https://digitalbanking.meyzer.xyz/authenticate';
  corpsecHomeUrl = corpsecUrl + '/authenticate';
  accountSignOutUrl = 'https://account.meyzer.xyz/session/end?client_id=central';

  //onBoardingUrl = 'https://corpsec.meyzer.xyz/onboarding';
  // formCorpsecUrl = "https://forms.meyzer360.com/corpsec";
  // formOnBoardingUrl = "https://forms.meyzer360.com/registerCompany"  
  corpsecFrontendCompanyIdUrl ='https://corpsec.meyzer.xyz/authenticate?companyId={companyId}'

  landing_demo_link_erp = "https://meyzer360.com/erp/"
  landing_demo_link_payroll = "https://meyzer360.com/hr-payroll-compliance/"
  landing_demo_link_pos = "https://meyzer360.com/cloud-pos/"
  landing_demo_link_crm = "https://meyzer360.com/crm/"
  landing_demo_link_ebrochure = "https://meyzer360.com/erp/"
  landing_demo_link_arap = "https://meyzer360.com/erp/"


} else if (env == "prod") {
//###### PROD ######
  frontendUrl = "https://central.meyzer360.com";
  corpsecUrl = "https://corpsec.meyzer360.com";
  oidcRedirectUrl = "https://central.meyzer360.com/auth-callback";
  oidcProvider = "https://account.meyzer360.com";
  apiUrl = "https://api.central.meyzer360.com/v1";
  apiCorpsecUrl = "https://api.corpsec.meyzer360.com/v1";
  gtm = "GTM-5WT25CCC";

  centralHomeUrl = 'https://account.meyzer360.com/auth?client_id=central&response_type=code&redirect_uri='+oidcRedirectUrl+'&code_challenge={code}&code_challenge_method={chmt}&scope=openid%20profile%20permissions';
  corpsecHomeUrl = corpsecUrl + '/authenticate';
  digitalBankingHomeUrl = 'https://digitalbanking.meyzer360.com/authenticate';
  digitalBankCollectUrl = 'https://go.meyzer360.com/meyzer_oauth?redirect=payment-link';
  accountSignOutUrl = 'https://account.meyzer360.com/session/end?client_id=central';  

  //onBoardingUrl = 'https://corpsec.meyzer360.com/onboarding';
  // formCorpsecUrl = "https://forms.meyzer360.com/corpsec";
  // formOnBoardingUrl = "https://forms.meyzer360.com/registerCompany"
  corpsecFrontendCompanyIdUrl ='https://corpsec.meyzer360.com/authenticate?companyId={companyId}'


  demo_link_payroll = "https://hrm.meyzer360.com/meyzer_oauth";
  demo_link_erp = "https://go.meyzer360.com/meyzer_oauth?redirect=home"
  demo_link_pos =  "https://go.meyzer360.com/meyzer_oauth?redirect=pos";
  demo_e_quotation = "https://go.meyzer360.com/meyzer_oauth?redirect=e-quotation";
  demo_link_srm = "https://go.meyzer360.com/meyzer_oauth?redirect=suppliers"
  demo_link_arap = "https://go.meyzer360.com/meyzer_oauth?redirect=arap"
  
  demo_digital_bank =  "https://digitalbanking.meyzer360.com/dashboard";
  demo_link_sales =  "https://go.meyzer360.com/superapp/setup.php";
  // demo_link_erp =  "https://go.meyzer360.com/index.php?mainmenu=home&idmenu=16418";
  // demo_link_payroll =  "https://hrm.meyzer360.com/sys/";
  // demo_link_pos =  "https://go.meyzer360.com/takepos/index.php?idmenu=33836&mainmenu=takepos&leftmenu=";
  //demo_link_crm =  "https://go.meyzer360.com/leads/propal/list.php?mainmenu=commercial&leftmenu=leads&idmenu=32799&load=1";
  demo_link_crm =  "https://crm.meyzer360.com/";
  demo_link_ebrochure =  "https://go.meyzer360.com/custom/brochure/view.php?mainmenu=commercial&leftmenu=brochures&idmenu=33060&load=1";
  //demo_link_arap = "https://1erp.sg/1erp/arap"
  
  

  landing_demo_link_erp = "https://meyzer360.com/erp/"
  landing_demo_link_payroll = "https://meyzer360.com/hr-payroll-compliance/"
  landing_demo_link_pos = "https://meyzer360.com/cloud-pos/"
  landing_demo_link_crm = "https://meyzer360.com/crm/"
  landing_demo_link_ebrochure = "https://meyzer360.com/erp/"
  landing_demo_link_arap = "https://meyzer360.com/erp/"
}





export default {
  STAGE_ENV: env,

  FRONTEND_URL: frontendUrl,
  CORPSEC_URL: corpsecUrl,

  OIDC_REDIRECT_URL: oidcRedirectUrl,
  OIDC_URL: oidcProvider,
  API_URL: apiUrl,
  API_CORPSEC_URL: apiCorpsecUrl,
  GTM: gtm,  
  
  CENTRAL_HOME_URL: centralHomeUrl,
  CORPSEC_HOME_URL: corpsecHomeUrl,
  DIGITAL_BANKING_HOME_URL: digitalBankingHomeUrl,
  DIGITAL_BANKING_COLLECT_URL: digitalBankCollectUrl,
  
  ACCOUNT_SIGN_OUT_URL: accountSignOutUrl,

  
  //ONBOARDING_URL: onBoardingUrl,  
  // FORM_CORPSEC_URL :formCorpsecUrl,
  // FORM_ONBOARDING_URL: formOnBoardingUrl,
  CORPSEC_FE_COMPANY_ID : corpsecFrontendCompanyIdUrl,

  
  DEMO_DIGITAL_BANK: demo_digital_bank,
  DEMO_LINK_SALES: demo_link_sales,
  DEMO_LINK_ERP: demo_link_erp,
  DEMO_LINK_PAYROLL: demo_link_payroll,
  DEMO_LINK_POS: demo_link_pos,
  DEMO_LINK_CRM: demo_link_crm,
  DEMO_LINK_EBROCHURE: demo_link_ebrochure,
  DEMO_LINK_ARAP:demo_link_arap,
  DEMO_LINK_EQUOTATION: demo_e_quotation,
  DEMO_LINK_SRM: demo_link_srm,

  DEMO_LINK_CDO:demo_link_cdo,
  DEMO_LINK_CFO:demo_link_cfo,
  DEMO_LINK_CIO:demo_link_cio,
  DEMO_LINK_CMO:demo_link_cmo,
  DEMO_LINK_DPO:demo_link_dpo,

  LANDING_DEMO_LINK_ERP: landing_demo_link_erp,
  LANDING_DEMO_LINK_PAYROLL: landing_demo_link_payroll,
  LANDING_DEMO_LINK_POS: landing_demo_link_pos,
  LANDING_DEMO_LINK_CRM: landing_demo_link_crm,
  LANDING_DEMO_LINK_EBROCHURE: landing_demo_link_ebrochure,
  LANDING_DEMO_LINK_ARAP:landing_demo_link_arap,

  // PAYROLL_URL: payrollUrl,
  // ACCOUNTING_URL: accountingUrl,
  // ONE_ERP_URL: oneErpUrl,
  // ONE_ERP_SIGN_UP_URL: oneErpSignUpUrl,  
  //V2_ONBOARDING_URL : v2onboardingUrl,  

};













//local

  // payrollUrl = '<pending>';
  // accountingUrl = '<pending>';
  // oneErpUrl = "https://sales.meyzer360.site/?com_url=83&ref=COMP2307-0071&source=meyzer&state=testing"
  // oneErpUrl = "https://sales.meyzer360.com/meyzer_oauth"
  // oneErupUrl = "https://1erp.sg/testing/?com_url=83&ref=COMP2307-0071"
  // oneErpUrl = "https://sales.meyzer360.site/meyzer_oauth?client_id=saleschannels"
  // oneErpUrl = "https://account.meyzer360.rocks/auth?client_id=saleschannels&response_type=code&redirect_uri=https://sales.meyzer360.site/meyzer_oauth&code_challenge=6UDGlFa45ar4rcbTXiyYRxsKoxOAF2teahokS7qlDXY&code_challenge_method=plain&scope=openid%20profile%20permissions"
  // oneErpSignUpUrl="https://meyzer360.com/sg/checkout/"
  //v2onboardingUrl = "https://localhost:3000/v2/onboarding";


//rocks
  
  // payrollUrl = '<pending>';
  // accountingUrl = '<pending>';
  // oneErpUrl = "https://sales.meyzer360.site/?com_url=83&ref=COMP2307-0071&source=meyzer&state=testing"
  // oneErpUrl = "https://sales.meyzer360.com/meyzer_oauth"
  // oneErpUrl = "https://sales.meyzer360.site/meyzer_oauth?client_id=saleschannels"
  // oneErpUrl = "https://account.meyzer360.rocks/auth?client_id=saleschannels&response_type=code&redirect_uri=https://sales.meyzer360.site/meyzer_oauth&code_challenge=6UDGlFa45ar4rcbTXiyYRxsKoxOAF2teahokS7qlDXY&code_challenge_method=plain&scope=openid%20profile%20permissions"
  // oneErpSignUpUrl="https://meyzer360.com/sg/checkout/"
  // v2onboardingUrl = "https://corpsec.meyzer360.com/v2/onboarding";


//stage

  // oneErpUrl = "https://sales.meyzer360.site/?com_url=83&ref=COMP2307-0071&source=meyzer&state=testing"
  // oneErpUrl = "https://sales.meyzer360.com/meyzer_oauth"
  // oneErpUrl = "https://sales.meyzer360.site/meyzer_oauth?client_id=saleschannels"
  // payrollUrl = '<pending>';
  // accountingUrl = '<pending>';
  // v2onboardingUrl = "https://corpsec.meyzer.xyz/v2/onboarding";
  // oneErpUrl = "https://account.meyzer.xyz/auth?client_id=saleschannels&response_type=code&redirect_uri=https://sales.meyzer360.site/meyzer_oauth&code_challenge={code}&code_challenge_method={chmt}&scope=openid%20profile%20permissions"
  // oneErpSignUpUrl="https://meyzer360.com/sg/checkout/"


//prod
  // payrollUrl = '<pending>';
  // accountingUrl = '<pending>';
  //onBoardingUrl = 'https://corpsec.meyzer360.com/onboarding';
  // oneErpUrl = "https://sales.meyzer360.com/?com_url=83&ref=COMP2307-0071&source=meyzer&state=testing"
  // oneErpUrl = "https://sales.meyzer360.com/meyzer_oauth?client_id=salesChannels"
  // oneErpUrl = "https://account.meyzer360.com/auth?client_id=saleschannels&response_type=code&redirect_uri=https://sales.meyzer360.com/meyzer_oauth&code_challenge={code}&code_challenge_method={chmt}&scope=openid%20profile%20permissions"
  // oneErpSignUpUrl="https://meyzer360.com/sg/checkout/"
  //v2onboardingUrl = "https://corpsec.meyzer360.com/v2/onboarding";