import { defineStore } from "pinia"
import _ from "lodash"
import { customJoi, prepareJoiErrorArr } from "@/models/customJoi"
import * as accessService from "@/services/service.access"
import * as companyService from "@/services/service.company"
import Utils from '@/helpers/Utils';
import Constant from "@/helpers/Constant";
import Cookies from 'js-cookie'

// import schema...

const obj = {
	companies: [],
	selectedCompany: false,
	loading:true
}

export const useStoreCompany = defineStore("company", {
	state: () => _.cloneDeep(obj),
	getters: {
		foo(state) { return "bar" },
		preferedSelectCompanyId: (state) => {
			return Cookies.get(Constant.COOKIE_PREFERED_SELECTED_COMPANY_ID);
		  },
	},
	actions: {
		reset() {
			Object.assign(this, _.cloneDeep(obj))
		},
		hasCompany() {
			return this.companies?.length > 0;
		},
		hasModuleAccess (moduleId = "digitalbank") {
			try {
				const activatedModule = this.selectedCompany?.activatedModule[moduleId];
				if (!activatedModule) {
					return false;
				}
				// const moduleAccess = this.selectedCompany?.moduleAccess[moduleId];
				// if (moduleAccess == 'viewer' || moduleAccess == 'none') {
				// 	return false;
				// }
			} catch (e) {
				//console.log("checkModuleAccess", e);
				return false;
			}
			return true;
		},
		 getMainDomain() {
			const hostname = window.location.hostname;
			const parts = hostname.split('.');
			if (parts.length > 2) {
				parts.shift(); 
			}
			return parts.join('.');
		},
		async onSelectCompany(c) {
			this.loading = true;
			window.scrollTo(0, 0);
			await Utils.sleep(300);
			this.selectedCompany = c;
			this.loading = false;
			Cookies.set(Constant.COOKIE_PREFERED_SELECTED_COMPANY_ID, c?.id, { domain: `.${this.getMainDomain()}` });
			//console.log("this.selectedCompany", this.selectedCompany, c)
		},
		initCompanyArrFromAccess(arr) {
			this.loading = true;
			const sortedData = arr.sort();
			this.companies = sortedData;
			let c;
			if (this.preferedSelectCompanyId) {
				c = _.find(sortedData, {id: this.preferedSelectCompanyId});
			}
			if (!c) {
				//this.preferedSelectCompanyId = null;
				c = sortedData[0];
			}
			
			//console.log("initCompanyArrFromAccess", this.preferedSelectCompanyId, arr, c)
			this.onSelectCompany(c);
			this.loading = false;			
		},
		async getCompanyRequests(companyId) {
			const requests = await companyService.getCompanyRequests(companyId)
			return requests.data;
		},
		async getCompanyMilestones(companyId) {
			const requests = await companyService.getCompanyMilestones(companyId)
			return requests.data;
		},
		async getDocumentById(companyId, documentId) {
			const requests = await companyService.getDocumentById(companyId, documentId)
			return requests.data;
		},
		async getDigibankBalById(companyId) {
			const requests = await companyService.getDigibankBalById(companyId)
			return requests.data;
		},

		async createCompanyLite(data) {
			const res = await companyService.createCompanyLite(data)
			return res.data;
		},

		async activateCompanyModule(companyId, data) {
			const res = await companyService.activateCompanyModule(companyId, data)
			return res.data;
		}
		



	},
})
