<script setup>
import { computed, ref, onBeforeMount, onMounted, watch, reactive } from "vue"
import { storeToRefs } from "pinia"
import Sidebar from "primevue/sidebar"
import { useStoreSite } from "@/stores/store.site"
import { useStoreForm } from "@/stores/store.form"
import env from "@/helpers/Environment"
import router from "@/router"
import Utils from "@/helpers/utils"
import { useConfirm } from "primevue/useconfirm"
import _ from "lodash"

const storeSite = useStoreSite()
const storeForm = useStoreForm()
const { showCorpsecOnboardingSideBarFlag } = storeToRefs(storeSite)
const confirm = useConfirm()

const rData = reactive({
	loading: false,
	disabledApplyBtnFlag: false,
	forms: [],
})

watch(showCorpsecOnboardingSideBarFlag, () => {
	//console.log("showCorpsecOnboardingSideBarFlag", showCorpsecOnboardingSideBarFlag.value)
	if (showCorpsecOnboardingSideBarFlag.value) {
		refreshData()
	}
})

const onClickBtn = (item) => {
	if (item === "reg-new-co") {
		//window.open(env.CORPSEC_URL + "/onboarding", "_blank")
		//window.open(env.CORPSEC_URL + "/authenticate?redirect=onboarding", "_blank")
		router.push("/forms/corpsec-register-new-company-combine");
	} else if (item == "come-onboard") {
		// window.open(env.FORM_CORPSEC_URL, "_blank")
		router.push("/forms/corpsec-transfer-in-company")
	}
}

onMounted(async () => {
	refreshData()
})

const refreshData = async () => {
	rData.loading = true
	const o1 = await storeForm.getCorpsecRegNewCos()
	const o2 = await storeForm.getCorpsecTransferInCos()
	//console.log("refreshData", o1, o2)
	await Utils.sleep(200)
	rData.disabledApplyBtnFlag = false
	const o = { data: [...o1.data, ...o2.data] } //merge array o1 & o2
	let data = o.data
	for (var i in data) {
		const a = data[i]
		//console.log(">>>", i, a, data[i] );
		if (
			a.formStatus == "draft" ||
			a.formStatus == "pending" ||
			a.formStatus == "processing" ||
			a.formStatus == "accepted"
		) {
			rData.disabledApplyBtnFlag = true
		}
		//console.log("o.data", o.data[0].updatedAt);
		o.data = _.orderBy(o.data, "updatedAt", "desc")
	}
	rData.forms = o.data
	rData.loading = false
}

const onResumeForm = (form) => {
	const formId = form.id
	const formName = form.formName
	//console.log("onResumeForm", form)
	//forms/corpsec-register-new-company/draft/xxxx
	//forms/corpsec-transfer-in-company/draft/xxxx
	router.push(`/forms/${formName}/draft/${formId}`)
}

const onViewForm = (form) => {
	const formId = form.id
	const formName = form.formName
	//forms/corpsec-register-new-company/view/xxxx
	//forms/corpsec-transfer-in-company/view/xxxx
	router.push(`/forms/${formName}/view/${formId}`)
}

function onClickTrashApplication(form) {
	const formId = form.id
	confirm.require({
		message: "Do you really want to proceed?",
		header: "Withdraw Submission",
		icon: "pi pi-exclamation-triangle text-lg",
		accept: async () => {
			try {
				await storeForm.withdrawById(formId)
				
				storeSite.showToast("Success", "Submission withdrawn", "success", 3000)
				await refreshData()
			} catch (e) {
				storeSite.showToast("Failed", err?.data?.validation || err?.message || "Error withdrawing", "error", 3000)
			}

			//rData.formData.personnels.splice(i, 1);
		},
		reject: () => {},
	})
}
</script>

<template>
	<Sidebar
		class="w-max"
		header=" "
		v-model:visible="showCorpsecOnboardingSideBarFlag"
		position="right">
		<div class="side-bar-content">
			<h2 >Let’s Get Started</h2>
			<p class="para-custom mt-2">
				Discover our comprehensive collection of tools, essential for streamlining your company operations.
			</p>

			<div class="pt-4 m-2">
          <template v-if="rData.loading">
            <CustomCard class="mt-1 p-4">
              <template #content>
                <Skeleton ></Skeleton>
                <Skeleton class="mt-4 w-10rem"></Skeleton>
                <Skeleton class="mt-2"></Skeleton>
              </template>
            </CustomCard>

            <CustomCard class="mt-3 p-4">
              <template #content>
                <Skeleton ></Skeleton>
                <Skeleton class="mt-4 w-10rem"></Skeleton>
                <Skeleton class="mt-2"></Skeleton>
              </template>
            </CustomCard>
          </template>

				<div v-else>
					<div v-if="rData.disabledApplyBtnFlag" class="font-body5 font-bold">You have an application in progress</div>
					<template v-for="(n, i) in rData.forms">
						<!-- v-if="i==0" -->
						<div class="p-3 border-3 border-round-sm border-orange-50 mb-3 mt-1 surface-50 mb-5" v-if="i == 0">
							<div class="flex justify-content-between">
								<!-- <div class="font-body2">{{ Utils.formatDateDetailReadable(n.updatedAt) }}</div> -->
								<div class="font-body2">{{ Utils.formatTimeFromNow(n.updatedAt) }} ago</div>
								<div class="font-body2"><b>Status:</b> {{ Utils.formatTitleCase(n.formStatus) }}</div>
							</div>
							<div>{{ n.formData.step1.companyName1 }}</div>
							<div class="flex justify-content-between">
								<CustomButton
									v-if="n.formStatus == 'draft'"
									square
									class="mt-2"
									outlined
									icon="pi pi-pencil"
									@click="onResumeForm(n)"
									label="Resume form application"
									></CustomButton
								>
								<CustomButton v-else square class="mt-2" outlined icon="pi pi-history" @click="onViewForm(n)"
									>View Submitted Details</CustomButton
								>
								<i
									v-if="n.formStatus == 'draft'"
									class="pi pi-trash cursor-pointer text-yellow-500 hover:text-yellow-300 mt-2"
									@click="onClickTrashApplication(n)" />
							</div>
						</div>
					</template>

					<CustomCard class="p-4">
						<template #content>
							<h3 class="title-custom text-custom-title">Create your company profile</h3>
							<div class="flex flex-column row-gap-3 mt-4">
								<p class="para-custom">Enter your company information to start using MEYZER360</p>

								<CustomButton
									class="yellow-btn w-max outline-btn"
									@click="storeSite.toggleCreateCompanyProfileDialog()"
									:disabled="rData.disabledApplyBtnFlag">
									<span class="button-text">Create Company Profile</span>
									<i class="pi pi-angle-right text-xs ml-2"></i>
									<!-- https://corpsec.meyzer360.com/onboarding/1 -->
								</CustomButton>
							</div>
						</template>
					</CustomCard>
					
					<CustomCard class="mt-3 p-4">
						<template #content>
							<h3 class="title-custom text-custom-title">Come onboard MEYZER360</h3>
							<div class="flex flex-column row-gap-3 mt-4">
								<p class="para-custom">
									<span class="font-medium">Appoint MEYZER360</span> as your company secretary and discover our tools to supercharge your business.
								</p>

								<CustomButton
									:class="['yellow-btn w-max', rData.disabledApplyBtnFlag ? '' : 'outline-btn']"
									@click="onClickBtn('come-onboard')"
									:disabled="rData.disabledApplyBtnFlag">
									<span class="button-text">Transfer to MEYZER360</span>
									<i class="pi pi-angle-right text-xs ml-2"></i>
								</CustomButton>
							</div>
						</template>
					</CustomCard>

					<hr class="mt-5 w-11">
					<Divider />


					<CustomCard class="mt-3 p-4">
						<template #content>
							<h3 class="title-custom text-custom-title">Register a New Company</h3>
							<div class="flex flex-column row-gap-3 mt-4">
								<p class="para-custom">Setup with MEYZER360 and incorporate your company the hassle-free way.</p>

								<CustomButton
									class="yellow-btn w-max"
									@click="onClickBtn('reg-new-co')"
									:disabled="rData.disabledApplyBtnFlag">
									<span class="button-text">Register a New Company</span>
									<i class="pi pi-plus text-xs ml-2"></i>
									<!-- https://corpsec.meyzer360.com/onboarding/1 -->
								</CustomButton>
							</div>
						</template>
					</CustomCard>



					<!-- <div class="p-4 border-1 border-round-sm border-300 mb-4 mt-2" >
            <div class="font-header2">Come onboard MEYZER360</div>
            <div class="flex flex-column row-gap-3 mt-4">
              <div class="font-body2 ">Onboard MEYZER360 and discover our tools to supercharge your business.</div>

                <button class="yellow-btn w-max" @click="onClickApply" :disabled="rData.disabledApplyBtnFlag">
                  <span class="button-text">Create Company Profile</span>
                  <i class="pi pi-angle-right text-xs ml-2"></i>
                </button>

            </div>
          </div> -->
				</div>
			</div>
		</div>
	</Sidebar>

	<!-- <Sidebar class="w-max" v-model:visible="showCorpsecOnboardingSideBarFlag" position="right">
    {{ rData.forms }}
    <div class="side-bar-content">
      <div class="font-header2">Let’s Get Started</div>
      <div class="font-body1">Discover our comprehensive collection of tools, essential for streamlining your company operations.</div>
      <div class="flex flex-column row-gap-3 pt-4" style="padding-left: 12px; padding-right: 12px;">
        <div class="p-4" style="border-radius: 4px; border: 1px solid #e1e2e6">
          <div class="font-header1">Register a New Company</div>
          <div class="flex flex-column row-gap-3" style="padding-top: 36px">
            <div class="font-body2">Setup with MEYZER360 and incorporate your company the hassle-free way.</div>
            <div>
              <button class="yellow-btn" @click="onClickBtn('reg-new-co')">
                <span class="button-text">Register a New Company </span>
                <i class="pi pi-plus text-xs ml-2"></i>
                https://corpsec.meyzer360.com/onboarding/1
              </button>
            </div>
          </div>
        </div>
        <div class="p-4" style="border-radius: 4px; border: 1px solid #e1e2e6">
          <div class="font-header1">Come onboard MEYZER360</div>
          <div class="flex flex-column row-gap-3" style="padding-top: 36px">
            <div class="font-body2" >Onboard MEYZER360 and discover our tools to supercharge your business.</div>
            <div>
              <button class="yellow-btn outline-btn" @click="onClickBtn('come-onboard')">
                <span class="button-text">Create Company Profile </span>
                <i class="pi pi-angle-right ml-2"></i>
                https://corpsec.meyzer360.com/onboarding/1
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Sidebar> -->
</template>

<style lang="scss" scoped>
.side-bar-content {
	padding-left: 8px;
	padding-right: 8px;
	max-width: 505px !important;
}

hr {
  height: 1px;
  border: none;
  background-color: lightgrey;
}

</style>
