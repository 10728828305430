<script setup>
import Carousel from 'primevue/carousel';

import ad1 from "@/assets/imgs/welcome/step01-2.png"
import ad2 from "@/assets/imgs/welcome/step03-1.png"

import { useStoreSite } from '@/stores/store.site';

const storeSite = useStoreSite()

const adCopy1 = "Click 'Digital Bank Account' now to unlock the convenience of global payments and transfers. Enjoy seamless transactions and unparalleled accessibility as you navigate the future of banking at your fingertips.";
const adCopy2 = "Click 'Apply Now' to seamlessly embark on the journey of creating your digital bank, enabling you to make hassle-free global payments and transfers. Your financial world, simplified and accessible at your fingertips.";

const ads = [{ img: ad1, copy:adCopy1 }, { img: ad2, copy:adCopy2 }]
</script>

<template>

<Carousel :value="ads" :numVisible="1">
    <template #item="{data}">
			<div class="surface-100 border-1 surface-border border-round mt-3 p-3 ">
				<Image :src="data.img" imageClass="w-full" :preview="storeSite.isMobile" />
			</div>
			<div class="text-left mt-4">{{data.copy}}</div>
    </template>
</Carousel>


</template>

<style scoped lang="scss">
.carousel__item {
	width:100%;
}

.carousel__slide {
	padding: 8px;
}

</style>
