import env from '@/helpers/Environment';
import { callApi, callApiUrl } from '@/services/WebApi';


export const oidcLogin = (data) => {
  const url = env.API_URL + "/" + "auth/oidc-login";
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': data.access_token, //accessToken
    'Token': data.id_token, //idToken    
  };
  return callApiUrl('POST', url, {}, headers)
}

export const logout = () =>
// remove user from local storage to log user out
// localStorage.removeItem('user');
  callApi('POST', 'auth/log-out');

export const resumeLogin = () =>
  // //console.log("resumeLogin ");
  callApi('POST', 'auth/resume-login');

export const refreshToken = () =>
  // //console.log("refreshToken ");
  callApi('POST', 'auth/refresh-token');

export const checkNotification = () =>
  callApi('GET', 'account/check-notification');


export const updateProfile = (data) =>
  // //console.log("refreshToken ");
  callApi('PUT', 'account/profile', data);

export const updateEmail = (data) => {
  return callApi('PUT', 'account/profile/update-email', data);
};

export const updatePassword = (data) => {
  return callApi('PUT', 'account/profile/update-password', data)
}






/*

export const accountService = {
  oidcLogin,
  initSignInMobile,
  submitOtp,
  logout,
  resumeLogin,
  refreshToken,
  updateProfile,
  updateEmail,
  updatePassword
};



const initSignInMobile = (data) => {
  return callApi('POST', `account/init-sign-in-mobile`, data)
};
const submitOtp = (data) => {
  return callApi('POST', `account/sign-in-verify-mobile-otp`, data);
}

*/